import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, EMPTY, filter, of, switchMap, takeUntil } from 'rxjs';
import { TuiDestroyService } from '@taiga-ui/cdk';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
  providers: [TuiDestroyService],
})
export class SearchComponent {
  @Output() searchEvent = new EventEmitter<string | null>();
  @Input() isLoading = false;

  public searchControl = new FormControl('');

  constructor(private readonly destroy$: TuiDestroyService) {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(2000),
        switchMap((value) => (value ? EMPTY : of(value))),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.searchEvent.emit(value);
      });
  }

  onClickEnter(value: string | null) {
    if (this.isLoading) return;

    this.searchEvent.emit(value);
  }
}
