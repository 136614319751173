import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

export type RetransmissionParams = {
  startDate: Date;
  endDate: Date;
  retranslatorId: number;
};

export type StatusRetransmission = 'running' | 'stopped' | 'paused' | 'failed' | 'completed';

export type RetransmissionStatusData = {
  progress: number;
  retranslatorId: number;
  status: StatusRetransmission;
  taskId: string;
};

export type ActiveRetransmission = {
  endDate: string;
  id: string;
  progressPercents: number;
  startDate: string;
  status: StatusRetransmission;
};

export type RetranslatorFullInfo = {
  account: number;
  activeRetransmission: ActiveRetransmission | null;
  id: number;
  name: string;
  protocol: number;
  serverAddress: string;
  serverPort: number;
  status: boolean;
  tiedObjects: { uniqueId: string | null; objectId: number }[];
};

export type ResponseCreateRetransmission = {
  createdAt: string;
  endDate: string;
  id: string;
  isActive: boolean;
  lastProcessedDate: any;
  progressPercents: number;
  startDate: string;
  status: StatusRetransmission;
};

@Injectable({
  providedIn: 'root',
})
export class RetranslatorsService {
  retransmissionStatus$: Subject<any> = new Subject();

  public transformRetransmissionStatus = (status: string) => `retransmission_statuses.${status}`;

  constructor(private httpClient: HttpClient) {}

  list(page?: number, perPage?: number, search?: string | null, sortedValue?: string | null) {
    let params = new HttpParams();

    if (page && perPage) {
      params = params.set('page', page?.toString()).set('per_page', perPage?.toString());
    }

    if (search) {
      params = params.set('search', search);
    }
    if (sortedValue) {
      params = params.set('ordering', sortedValue);
    }

    return this.httpClient.get('cms/retranslators/', { params });
  }

  logs(
    id: number,
    date: any,
    isFilter: boolean,
    filtersObj?: any,
    page?: number,
    perPage?: number,
  ) {
    let params = new HttpParams();

    params = params.set('entity', 'retranslator');
    params = params.set('entity_id', id.toString());

    params = params.set('t_after', date.startDate).set('t_before', date.endDate);

    if (page && perPage) {
      params = params.set('page', page.toString()).set('per_page', perPage.toString());
    }

    return this.httpClient.get(`cms/journal/`, { params });
  }

  getRetranslatorById(retranslatorsId: number): Observable<RetranslatorFullInfo> {
    return this.httpClient.get<RetranslatorFullInfo>(`cms/retranslators/${retranslatorsId}/`);
  }

  create(form: any) {
    return this.httpClient.post('cms/retranslators/', form);
  }

  update(body: any, retranslatorsId: any) {
    return this.httpClient.put(`cms/retranslators/${retranslatorsId}/`, body);
  }

  updateStateRetranlator(retranslatorsId: number, state: boolean) {
    return this.httpClient.post(`cms/retranslators/${retranslatorsId}/activate/`, { state });
  }

  delete(retranslatorsId: number) {
    return this.httpClient.delete(`cms/retranslators/${retranslatorsId}/`);
  }

  // список задач повторной ретрансляции
  getRetransmissionList(retranslatorsId: number, page: number, perPage: number) {
    let params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());

    return this.httpClient.get<{ results: ResponseCreateRetransmission[]; count: number }>(
      `cms/retranslators/${retranslatorsId}/retransmissions/`,
      { params },
    );
  }

  // получение задачи повторной ретрансляции
  getRetransmissionTask(retranslatorsId: number, taskId: string) {
    return this.httpClient.get(`cms/retranslators/${retranslatorsId}/retransmissions/${taskId}/`);
  }

  // создание задачи повторной ретрансляции
  createRetransmission(
    retranslatorsId: number,
    body: RetransmissionParams,
  ): Observable<ResponseCreateRetransmission> {
    return this.httpClient.post<ResponseCreateRetransmission>(
      `cms/retranslators/${retranslatorsId}/retransmissions/`,
      body,
    );
  }

  // полная остановка задачи
  stopRetransmission(retranslatorsId: number, taskId: string) {
    return this.httpClient.post(
      `cms/retranslators/${retranslatorsId}/retransmissions/${taskId}/stop/`,
      {},
    );
  }

  // приостановка задачи
  pauseRetransmission(retranslatorsId: number, taskId: string) {
    return this.httpClient.post(
      `cms/retranslators/${retranslatorsId}/retransmissions/${taskId}/pause/`,
      {},
    );
  }

  // возобновление задачи
  resumeRetransmission(retranslatorsId: number, taskId: string) {
    return this.httpClient.post(
      `cms/retranslators/${retranslatorsId}/retransmissions/${taskId}/resume/`,
      {},
    );
  }
}
