import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RetranslatorsService } from '@services/retranslators.service';
import { UsersService } from '@services/users.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private userSocket: null | WebSocket = null;

  public isConnected: boolean = false;

  constructor(
    private retranslatorsService: RetranslatorsService,
    private usersService: UsersService,
  ) {}

  disconnectUserSocket() {
    this.userSocket?.close();
    this.userSocket = null;
    this.isConnected = false;
  }

  connectUserSocket(currentUserInfo: any) {
    if (!this.isConnected) {
      const userId = currentUserInfo.id;

      const token = sessionStorage.getItem(environment.tokenKey);
      this.userSocket = new WebSocket(
        `${environment.websocketUrl}live-cms-data/${userId}?token=${token}`,
      );
      this.userSocket.onopen = () => {
        console.log(`connected ${userId}`);
        this.isConnected = true;
      };
      this.userSocket.onmessage = (data: any) => {
        const socketData = JSON.parse(data.data);
        if (socketData.type === 'retransmission__status') {
          this.retransmissionStatusHandler(socketData);
        } else if (socketData.type === 'current_user__refresh') {
          this.currentUserRefreshHandler(socketData);
        }
      };
      this.userSocket.onclose = (event) => {
        console.warn(event);
        console.log(`disconnected ${userId}`);
        this.isConnected = false;

        const token = sessionStorage.getItem(environment.tokenKey);
        if (token) {
          this.connectUserSocket(currentUserInfo);
        }
      };
    }
  }

  retransmissionStatusHandler(data: any) {
    this.retranslatorsService.retransmissionStatus$.next(data.data);
  }

  currentUserRefreshHandler(data: any) {
    const eventType = data?.data?.eventType || null;

    if (eventType === 'change_password') {
      if (this.usersService.selfChangePassword$.getValue()) {
        this.usersService.selfChangePassword$.next(false);
        return;
      }
      sessionStorage.clear();
      window.location.href = `/auth/login/?eventType=${eventType}`;
    }
  }
}
