import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { TabInfo, tabs } from './sidebar-tabs-config';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  providers: [TuiDestroyService],
})
export class SidebarComponent implements OnInit {
  @Output() changeSidebarState = new EventEmitter();

  isExpanded = !!localStorage.getItem('isExpanded');

  protected readonly TABS: TabInfo[] = tabs;

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    public translate: TranslateService,
    private store: Store,
    private destroy$: TuiDestroyService,
  ) {
    this.store
      .select(selectCurrentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.TABS.map((tab) => {
            if (tab.key === 'trash') {
              tab.access = user.accountType === 'partner' && (user.isAdmin || user.hasAdminAccess);
            }
          });
        }
      });
  }

  ngOnInit(): void {
    this.changeSidebarState.emit(this.isExpanded);
  }

  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    this.changeSidebarState.emit(this.isExpanded);
    this.isExpanded
      ? localStorage.setItem('isExpanded', 'true')
      : localStorage.removeItem('isExpanded');
  }

  onDblClickLogo() {
    window.open(window.location.href, '_blank');
  }
}
