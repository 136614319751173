export const environment = {
  production: false,
  apiUrl: 'https://cms.dev.axenta.cloud/api/',
  clientUrl: 'https://dev.axenta.cloud',
  cmsUrl: 'https://cms.dev.axenta.cloud',
  websocketUrl: 'wss://dev.axenta.cloud/ws/',
  locales: ['en', 'ru'],
  defaultLocale: 'en',
  systemType: 'cms',
  tokenKey: 'cmsToken',
};
