import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiAlertService, TuiDialogContext } from '@taiga-ui/core';
import { matchFieldsValidator } from '@common/utils/validators/match-field-validator';
import { switchMap, takeUntil } from 'rxjs';
import { UsersService } from '@services/users.service';
import { ErrorService } from '@common/services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less'],
  providers: [TuiDestroyService],
})
export class ChangePasswordComponent {
  formGroup: FormGroup = new FormGroup({
    oldPassword: new FormControl(null, [Validators.required]),
    newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl(null, [
      Validators.required,
      matchFieldsValidator('newPassword'),
    ]),
  });

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<any, any>,
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private translate: TranslateService,
    private userService: UsersService,
    private destroy$: TuiDestroyService,
    private errorHandler: ErrorService,
  ) {
    this.formGroup
      .get('newPassword')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formGroup.get('confirmPassword')?.updateValueAndValidity();
      });
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.userService.selfChangePassword$.next(true);

    this.userService
      .changePassword(this.formGroup.value)
      .pipe(
        switchMap((res: any) => {
          sessionStorage.setItem(environment.tokenKey, res.token);
          this.context.completeWith(true);
          return this.alerts.open(this.translate.instant('Password changed successfully'));
        }),
      )
      .subscribe({
        next: () => {},
        error: (error: any) => {
          this.userService.selfChangePassword$.next(false);
          this.errorHandler.simpleErrorHandler(error, this.formGroup);
        },
      });
  }

  onCancel() {
    this.context.completeWith(false);
  }
}
