export interface TabInfo {
  title: string;
  key: string;
  routerLink: string;
  iconSrc: string;
  access: boolean;
}

export const tabs: TabInfo[] = [
  {
    title: 'Accounts',
    key: 'accounts',
    routerLink: '/main/accounts',
    iconSrc: 'tuiIconKey',
    access: true,
  },
  {
    title: 'Objects',
    key: 'objects',
    routerLink: '/main/objects',
    iconSrc: 'common/assets/icons/objects.svg',
    access: true,
  },
  {
    title: 'Users',
    key: 'users',
    routerLink: '/main/users',
    iconSrc: 'common/assets/icons/users.svg',
    access: true,
  },
  {
    title: 'Retranslators',
    key: 'retranslators',
    routerLink: '/main/retranslators',
    iconSrc: 'tuiIconExternalLink',
    access: true,
  },
  {
    title: 'Trash',
    key: 'trash',
    routerLink: '/main/trash',
    iconSrc: 'common/assets/icons/trash.svg',
    access: true,
  },
];
