import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AllPresetsModel } from '@common/components/create-user/models/users.models';
import { environment } from '../environments/environment';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

// import {AllPresetsModel} from "@common/components/create-user/models/users.models";

export interface IUsersListRequestParams {
  page?: number | null;
  perPage?: number | null;
  search?: string | null;
  sortedValue?: string | null;
  fields?: string[] | null;
  accountId?: number | null;
  child_account_id?: number | null;
  object_account_id?: number | null;
  has_admin_access?: boolean | null;
  accountType?: 'partner' | 'client' | null;
  isAdmin?: boolean | null;
  isActive?: boolean | null;
  withAccess?: boolean | null;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  selfChangePassword$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  filterParams: any = [
    {
      type: 'select',
      control: 'isEntity',
      title: '',
      value: 0,
      availableParams: [
        { id: 0, title: 'User activity' },
        { id: 1, title: 'Operations on user' },
      ],
    },
  ];
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private httpClient: HttpClient,
  ) {}

  usersList(
    params: IUsersListRequestParams | null = {
      page: null,
      perPage: null,
      search: null,
      sortedValue: null,
      fields: null,
      accountId: null,
      child_account_id: null,
      object_account_id: null,
      has_admin_access: null,
      accountType: null,
      isAdmin: null,
      isActive: null,
      withAccess: null,
    },
  ) {
    let httpParams = new HttpParams();

    if (params?.page && params?.perPage) {
      httpParams = httpParams
        .set('page', params?.page.toString())
        .set('per_page', params?.perPage.toString());
    }
    if (params?.search) {
      httpParams = httpParams.set('search', params?.search);
    }
    if (params?.sortedValue) {
      httpParams = httpParams.set('ordering', params.sortedValue);
    }
    if (params?.fields?.length) {
      httpParams = httpParams.set('fields', params.fields.toString());
    }
    if (params?.accountId) {
      httpParams = httpParams.set('account_id', params.accountId.toString());
    }
    if (params?.child_account_id) {
      httpParams = httpParams.set('child_account_id', params.child_account_id.toString());
    }
    if (params?.object_account_id) {
      httpParams = httpParams.set('object_account_id', params.object_account_id.toString());
    }
    if (params?.accountType) {
      httpParams = httpParams.set('account_type', params.accountType.toString());
    }
    if (typeof params?.has_admin_access === 'boolean') {
      httpParams = httpParams.set('has_admin_access', params.has_admin_access.toString());
    }
    if (typeof params?.isAdmin === 'boolean') {
      httpParams = httpParams.set('is_admin', params.isAdmin.toString());
    }
    if (typeof params?.isActive === 'boolean') {
      httpParams = httpParams.set('is_active', params.isActive.toString());
    }
    if (typeof params?.withAccess === 'boolean') {
      httpParams = httpParams.set('with_access', params.withAccess.toString());
    }
    return this.httpClient.get('cms/users/', { params: httpParams });
  }

  userById(userId: number) {
    return this.httpClient.get(`cms/users/${userId}/`);
  }

  linkingObjectsByUserId(userId: number) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('user_id', userId.toString());

    return this.httpClient.get(`cms/object_user_access/`, { params: httpParams });
  }

  logs(
    id: number,
    date: any,
    isFilter: boolean,
    filtersObj?: any,
    page?: number,
    perPage?: number,
  ) {
    let params = new HttpParams();

    if (!isFilter) {
      params = params.set('user_id', id.toString());
    } else {
      for (let [key, value] of Object.entries(filtersObj)) {
        if (key === 'isEntity') {
          if (value === 0) {
            params = params.set('user_id', id.toString());
          } else {
            params = params.set('entity_id', id.toString());
            params = params.set('entity', 'user');
          }
        }
      }
    }

    params = params.set('t_after', date.startDate).set('t_before', date.endDate);

    if (page && perPage) {
      params = params.set('page', page.toString()).set('per_page', perPage.toString());
    }

    return this.httpClient.get(`cms/journal/`, { params });
  }

  createUser(body: any) {
    return this.httpClient.post('cms/users/', body);
  }

  updateUser(body: any, userId: any) {
    return this.httpClient.patch(`cms/users/${userId}/`, body);
  }

  updateUserProfile(body: any) {
    return this.httpClient.put('update_user_profile/', body);
  }

  changePassword(body: any) {
    return this.httpClient.put('change_password/', body);
  }

  updateUserPassword(body: any) {
    return this.httpClient.post(`cms/update_user_password/`, body);
  }

  updateIsActiveUser(userId: number, state: boolean) {
    return this.httpClient.post(`cms/users/${userId}/activate/`, { state });
  }

  delete(userId: number) {
    return this.httpClient.delete(`cms/users/${userId}/`);
  }

  loginAs(userId: number) {
    return this.httpClient.post(`cms/users/login_as/`, { userId });
  }

  loginAxentaCMS(userId: number) {
    this.loginAs(userId).subscribe({
      next: (value: any) => {
        const token = value?.token;
        const url = environment.cmsUrl;
        window.open(`${url}/auth/login/?authToken=${token}`);
      },
      error: (err: any) => {
        if (err?.formError?.userId) {
          this.alerts
            .open(err.formError.userId, {
              status: TuiNotification.Error,
              autoClose: 5000,
            })
            .subscribe();
        }
      },
    });
  }

  loginAxenta(userId: number) {
    this.loginAs(userId).subscribe({
      next: (value: any) => {
        const token = value?.token;
        const url = environment.clientUrl;
        window.open(`${url}/auth/login/?authToken=${token}`);
      },
      error: (err: any) => {
        if (err?.formError?.userId) {
          this.alerts
            .open(err.formError.userId, {
              status: TuiNotification.Error,
              autoClose: 5000,
            })
            .subscribe();
        }
      },
    });
  }

  getCurrentUser() {
    return this.httpClient.get('current_user/');
  }

  getAccessPresets(): Observable<AllPresetsModel | null> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('access_presets/');
    // TODO: update with any mock data
    return of(null);
  }

  updateAccessPresets(body: AllPresetsModel): Observable<AllPresetsModel> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('access_presets/');
    // TODO: update with any mock data
    return of(body);
  }

  // TODO: delete when backend is ready
  logmessages = [
    { time: '2018-01-01 00:00:00', message: 'message' },
    { time: '2018-01-01 00:00:00', message: 'message' },
  ];

  getUserLogs(body: any, id: string): Observable<any[]> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('users/logs/' + id, body);
    return of(this.logmessages);
  }

  sessions = {
    access: ['aaaa', 'bbbb', 'cccc'],
    notifications: ['1111', '2222', '3333'],
  };

  getUserSessions(id: string): Observable<any> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('users/sessions/' + id);
    return of(this.sessions);
  }
}
